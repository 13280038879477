<template>
  <div class="index">
    <router-view />
    <van-tabbar v-model="active" active-color="#5d74f2" @change="onChange">
      <van-tabbar-item name="home" icon="home-o">{{$t('首页')}}</van-tabbar-item>
      <van-tabbar-item name="mine" icon="user-o">{{$t('我的')}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    return {
      active: "home",
    };
  },
  mounted(){
    if(this.$route.path=='/mine'){
      this.active='mine'
    }
  },
  methods: {
    onChange(name){
      this.$router.push(`/${name}`)
    }
  },
};
</script>
<style scoped>
</style>
